import {Component} from "react";
import React from "react";
import { Link } from "react-router-dom";
import { CUR_LANG } from '../Const';

class Language extends Component {
    render() {
        if (CUR_LANG === "en"){
            return (
                <div className="header--locale">
                    <a href="" className="ru">ru</a>
                    <a href="" className="active en">en</a>
                </div>
            );
        } else{
            return (
                <div className="header--locale">
                    <a href="" className="active ru">ru</a>
                    <a href="" className="en">en</a>
                </div>
            );
        }
    }
}

export default Language;
