import {Component} from "react";
import React from "react";
import close from '../close.svg';
import Slider from "react-slick";
import { BrowserRouter as Router, Route, NavLink, Link, Switch, NavNavLink } from "react-router-dom";
import { CUR_LANG } from '../Const';
import { DOMAIN_NAME } from '../Const'

class MainSlider extends Component {
	constructor(props){
		super(props);
		this.state = {
			data: []
		};
	}
	componentWillMount(){
		let url = DOMAIN_NAME+'/'+CUR_LANG+'/section/get';
        fetch(url, {

        }).
        then(response => response.json()).then((data) => {
            this.setState({
                data: data
            });
        });
	}

	render() {
		return (
			<ListItemSlider data={this.state.data}/>
		);
	}
}

class ListItemSlider extends React.Component {
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render(){
		const settings = {
			  dots: true,
			  speed: 500,
			  infinite: true,
			  swipeToSlide: true,
			  slidesToShow: 1,
			  slidesToScroll: 1,
			  vertical: true,
			  verticalSwiping: true,
			  swipe: true,
			  beforeChange: function(currentSlide, nextSlide) {
				// console.log("before change", currentSlide, nextSlide);
			  },
			  afterChange: function(currentSlide) {
				// console.log("after change", currentSlide);
			  }
			};
		return (
		<Slider ref={c => (this.slider = c)} {...settings}>
			{this.props.data.map((item, index) => {
				let nextItem  = this.props.data[index+1];
				let prevItem  = '';
				let nextIndex = index+1;
				let btnText;
                if (CUR_LANG ==="ru"){
                    btnText='Подробнее';
                } else {
				    btnText='More';
                }
				if (!nextItem) {
					prevItem  = this.props.data[0].title;
					nextIndex = 1;
				} else {
					prevItem  = nextItem.title;
					nextIndex = index+2;
				}
				if(item.is_main == 1){
					return (
						<section key={index} id={index} className="slides">
							<div className="slides--background">
								<div className="slides--background--item"></div>
								<div className="slides--background--item"></div>
								<div className="slides--background--item"></div>
								<div className="slides--background--item"></div>
								<div className="slides--background--item"></div>
								<div className="slides--background--item"></div>
							</div>
							<div className="slides-description" id={item.is_main}>
								<div className="slides-description--title">{item.title}</div>
								<div className="slides-description--description">{item.desc}</div>
								<div className="slides-description--more">
									<div className="slides-description--more-link slides-description--more-js">
										<NavLink to={item.button}>{btnText}</NavLink>
									</div>
								</div>
							</div>
							<div className="slides--more slides--more-js">
								<div className="slides--more--btn">
								<img src={close} alt="Закрыть" />
							</div>
							<div className="slides--more--description">
								{item.anonce}
							</div>
							</div>
                            <div className="popup-video">

                            </div>
							<div className="slides-next-page" onClick={this.next}>
                                <div className="slides-next-page--next-number">0{nextIndex}</div>
                                <div className="slides-next-page--next-title">{prevItem}</div>
							</div>
                            <NavLink className="close-slider close-slider--js" to="/"></NavLink>
						</section>
                    )
				} else {
                }
			})}
		</Slider>
		)
	}
}
export default MainSlider;
