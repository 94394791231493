document.addEventListener('DOMContentLoaded', function(){
    // Выдвигание плашки если активен url
    let currentURL = window.location.pathname;
    if (currentURL !== '/'){
        document.querySelector('.app-content').classList.add("show-more-info");
        document.querySelector('.slides-more-info').classList.add('active');
        document.querySelector('.App').classList.add("scroll-aside-js");
    }
    if(currentURL === "/produkty"){
        document.querySelector('.App').classList.add("products-page");
        document.querySelector('.App').classList.add("scroll-aside-js");
    }
    setInterval(function(){
        document.querySelectorAll('.sertificate img').forEach(function(element) {
            element.onclick = function() {
                var darkLayer = document.createElement('div'); // слойslides-description--more-js затемнения
                var closeBtn = document.getElementById('popupWin--close');
                darkLayer.id = 'shadow'; // id чтобы подхватить стиль
                document.body.appendChild(darkLayer); // включаем затемнение
                var modalWin = document.getElementById('popupWin'); // находим наше "окно"
                modalWin.style.display = 'block'; // "включаем" его
                var imgSource = this.getAttribute('src');
                document.getElementById("popupWin--img").src=imgSource;
                document.querySelector('#shadow').classList.add('zzz');

                var imgSource = document.querySelector('#popupWin--img').getAttribute('src');
                var splitSrc = imgSource.split("/");
                var lastElem = splitSrc[splitSrc.length - 1];
                var replaceNum = parseInt(lastElem.replace(/[^-0-9]/gim,''));
                var imgCount = replaceNum;
                document.querySelectorAll('#popupWin .next').forEach(function(element) {
                    element.onclick = function() {
                        if (imgCount++ >= 13) {
                            document.getElementById("popupWin--img").src='http://metallinvest55.com/static/media/sertificate/13.jpg';
                            imgCount = 13;
                        } else if (imgCount++ <= 1) {
                            document.getElementById("popupWin--img").src='http://metallinvest55.com/static/media/sertificate/1.jpg';
                            imgCount = 1;
                        } else {
                            document.getElementById("popupWin--img").src='http://metallinvest55.com/static/media/sertificate/'+(imgCount++)+'.jpg';
                        }
                    };
                });
                document.querySelectorAll('#popupWin .prev').forEach(function(element) {
                    element.onclick = function() {
                        if (imgCount-- <= 1) {
                            document.getElementById("popupWin--img").src='http://metallinvest55.com/static/media/sertificate/1.jpg';
                            imgCount = 1;
                        } else if (imgCount-- >= 13) {
                            document.getElementById("popupWin--img").src='http://metallinvest55.com/static/media/sertificate/13.jpg';
                            imgCount = 13;
                        } else {
                            document.getElementById("popupWin--img").src='http://metallinvest55.com/static/media/sertificate/'+(imgCount--)+'.jpg';
                        }
                    };
                });
                darkLayer.onclick = function () {  // при клике на слой затемнения все исчезнет
                    document.querySelector('#shadow').classList.remove('zzz');
                    modalWin.style.display = 'none'; // делаем окно невидимым
                    darkLayer.parentNode.removeChild(darkLayer); // удаляем затемнение
                    return false;
                };
                closeBtn.onclick = function () {  // при клике на слой затемнения все исчезнет
                    document.querySelector('#shadow').classList.remove('zzz');
                    darkLayer.parentNode.removeChild(darkLayer); // удаляем затемнение
                    modalWin.style.display = 'none'; // делаем окно невидимым
                    return false;
                };
            };
        });
        document.querySelectorAll('.slides-more-info-right .more').forEach(function(element) {
            element.onclick = function() {
                var darkLayer = document.createElement('div'); // слой затемнения
                var closeBtn = document.getElementById('popupWin--close');
                darkLayer.id = 'shadow'; // id чтобы подхватить стиль
                document.body.appendChild(darkLayer); // включаем затемнение
                var modalWin = document.getElementById('popupWin2'); // находим наше "окно"
                modalWin.style.display = 'block'; // "включаем" его
                var imgSource = document.querySelectorAll('.sertificate');
                imgSource.innerHTML = modalWin.innerHTML;
                var imgList = document.querySelector('.slides-more-info-right .sertificate').innerHTML;
                document.querySelector('#popupWin2 .sertificate').innerHTML = imgList;
                darkLayer.onclick = function () {  // при клике на слой затемнения все исчезнет
                    document.querySelector('#shadow').classList.remove('zzz');
                    modalWin.style.display = 'none'; // делаем окно невидимым
                    darkLayer.parentNode.removeChild(darkLayer); // удаляем затемнение
                    return false;
                };
                closeBtn.onclick = function () {  // при клике на слой затемнения все исчезнет
                    document.querySelector('#shadow').classList.remove('zzz');
                    darkLayer.parentNode.removeChild(darkLayer); // удаляем затемнение
                    modalWin.style.display = 'none'; // делаем окно невидимым
                    return false;
                };
            };
        });

        document.querySelectorAll('.popup-video').forEach(function(element) {
            element.onclick = function() {
                var darkLayer = document.createElement('div'); // слой затемнения
                var closeBtn = document.getElementById('popupWin--close2');
                darkLayer.id = 'shadow'; // id чтобы подхватить стиль
                document.body.appendChild(darkLayer); // включаем затемнение
                var modalWin = document.getElementById('popupWin3'); // находим наше "окно"
                modalWin.style.display = 'block'; // "включаем" его
                darkLayer.onclick = function () {  // при клике на слой затемнения все исчезнет
                    document.querySelector('#shadow').classList.remove('zzz');
                    modalWin.style.display = 'none'; // делаем окно невидимым
                    darkLayer.parentNode.removeChild(darkLayer); // удаляем затемнение
                    var my_video = document.getElementById("popupWin3");
                    var my_video = my_video.getElementsByTagName("iframe")[0].contentWindow;
                    my_video.postMessage('{"event": "command", "func": "pauseVideo", "args": ""}', "*");
                    return false;
                };
                closeBtn.onclick = function () {  // при клике на слой затемнения все исчезнет
                    document.querySelector('#shadow').classList.remove('zzz');
                    darkLayer.parentNode.removeChild(darkLayer); // удаляем затемнение
                    modalWin.style.display = 'none'; // делаем окно невидимым
                    var my_video = document.getElementById("popupWin3");
                    var my_video = my_video.getElementsByTagName("iframe")[0].contentWindow;
                    my_video.postMessage('{"event": "command", "func": "pauseVideo", "args": ""}', "*");
                    return false;
                };
            };
        });
        var slider = document.querySelector('.sertificate');
        var isDown = false;
        var startX;
        var scrollLeft;
        if (slider){
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        }
        // Мобильное меню
        document.querySelectorAll('.burger').forEach(function(element) {
            element.onclick = function() {
                this.classList.toggle('burger-active');
                document.querySelector('.slides-more-info-left').classList.toggle("slides-more-info-left__active");
            };
        });
        // Клик на плюс на слайдах
        document.querySelectorAll('.slides--more-js .slides--more--btn').forEach(function(element) {
            element.onclick = function() {
                element.parentElement.classList.toggle("slides--more__active");
            };
        });
        // Клик на подробнее на слайдах
        document.querySelectorAll('.slides-description--more-js a:not([href="/produkty"]').forEach(function(element) {
            element.onclick = function() {
                document.querySelector('.app-content').classList.add("show-more-info");
                document.querySelector('.slides-more-info').classList.add('active');
                document.querySelector('.App').classList.remove("products-page");
                document.querySelector('.App').classList.add("scroll-aside-js");
                document.querySelector('.slides-more-info-right').classList.add("slides-more-info-right__slide");
                document.querySelector('.mobile-back').classList.add("mobile-back--active");
                document.querySelector('.header').classList.add("white");
            };
        });
        document.querySelectorAll('.slides-description--more-js a[href="/produkty"]').forEach(function(element) {
            element.onclick = function() {
                document.querySelector('.app-content').classList.add("show-more-info");
                document.querySelector('.slides-more-info').classList.add('active');
                document.querySelector('.App').classList.add("products-page");
                document.querySelector('.App').classList.remove("scroll-aside-js");
                document.querySelector('.slides-more-info-right').classList.add("slides-more-info-right__slide");
                document.querySelector('.mobile-back').classList.add("mobile-back--active");
                document.querySelector('.header').classList.add("white");
            };
        });
        document.querySelectorAll('.another-link a').forEach(function(element) {
            element.onclick = function() {
                document.querySelector('.app-content').classList.add("show-more-info");
                document.querySelector('.slides-more-info').classList.add('active');
                document.querySelector('.App').classList.remove("products-page");
                document.querySelector('.App').classList.add("scroll-aside-js");
            };
        });
        document.querySelectorAll('.slides-more-info-left li').forEach(function(element) {
            element.onclick = function() {
                document.querySelector('.app-content').classList.add("show-more-info");
                document.querySelector('.slides-more-info').classList.add('active');
                document.querySelector('.slides-more-info-right').classList.add('slides-more-info-right__slide');
                document.querySelector('.mobile-back').classList.add('mobile-back--active');
                document.querySelector('.header').classList.add("white");
                document.querySelector('.ScrollbarsCustom-wrapper').classList.add('processing');
                setTimeout("document.querySelector('.ScrollbarsCustom-wrapper').classList.remove('processing')", 1500);
            };
        });
        document.querySelectorAll('.mobile-back').forEach(function(element) {
            element.onclick = function() {
                document.querySelector('.slides-more-info-right').classList.remove('slides-more-info-right__slide');
                document.querySelector('.mobile-back').classList.remove('mobile-back--active');
                document.querySelector('.header').classList.remove("white");
            };
        });
        document.querySelectorAll('.slides-more-info-left--menuList a').forEach(function(element) {
            element.onclick = function() {
                document.querySelector('.App').classList.remove("products-page");
                document.querySelector('.App').classList.add("scroll-aside-js");
            };
        });
        document.querySelectorAll('.products-link a').forEach(function(element) {
            element.onclick = function() {
                document.querySelector('.App').classList.add("products-page");
                document.querySelector('.App').classList.add("scroll-aside-js");
                document.querySelector('.ScrollbarsCustom-wrapper').classList.remove('processing');
            };
        });
        // Закрыть детальную
        document.querySelectorAll('.close-slider--js').forEach(function(element) {
            element.onclick = function() {
                document.querySelector('.app-content').classList.remove("show-more-info");
                document.querySelector('.slides-more-info').classList.remove('active');
                document.querySelector('.App').classList.remove("products-page");
                document.querySelector('.App').classList.remove("scroll-aside-js");
                document.querySelector('a').classList.remove("active-link");
            };
        });
        // Скролл слайдов
        window.addEventListener('wheel', function(e) {
            if (document.querySelector('.App').classList.contains("scroll-aside-js")){

            } else {
                if (e.deltaY < 0) {
                    document.querySelector(".slick-arrow.slick-prev").click();
                }
                if (e.deltaY > 0) {
                    document.querySelector(".slick-arrow.slick-next").click();
                }
            }
        });
        // =============================
    }, 1000);
});
