import {Component} from "react";
import logo from "../logo.svg";
import Language from "./Language";
import arrow from "../images/arrow.svg";
import React from "react";
import { BrowserRouter as Router, Route, NavLink, Switch, NavNavLink } from "react-router-dom";
import { CUR_LANG } from '../Const';

class Header extends Component {
    render() {
        let menuItem=[];
        if (CUR_LANG ==="ru"){
            menuItem=['О нас', 'Продукты', 'Сфера применения','Логистика','Карьера','Контакты'];
        } else {
            menuItem=['About', 'Products', 'Scope of application','Logistics','Career','Contacts'];
        }
        return (
            <header className="header">
                <div className="container">
                    <div className="header--logotype">
                        <img src={logo} alt="Логотип" />
                    </div>
                    <ul className="header--menu">
                        <li className = "another-link">
                            <NavLink activeClassName="active-link" to="/about">{menuItem[0]}</NavLink>
                        </li>
                        <li className="products-link">
                            <NavLink activeClassName="active-link" to="/produkty">{menuItem[1]}</NavLink>
                        </li>
                        <li className = "another-link">
                            <NavLink activeClassName="active-link" to="/sfera-primeneniya">{menuItem[2]}</NavLink>
                        </li>
                        <li className = "another-link">
                            <NavLink activeClassName="active-link" to="/logistika">{menuItem[3]}</NavLink>
                        </li>
                        <li className = "another-link">
                            <NavLink activeClassName="active-link" to="/karera">{menuItem[4]}</NavLink>
                        </li>
                        <li className = "another-link">
                            <NavLink activeClassName="active-link" to="/kontakty">{menuItem[5]}</NavLink>
                        </li>
                    </ul>
                    <Language/>
                    <div className='burger'>
                        <div className='burger-inner'></div>
                    </div>
                    <div className="mobile-back">
                        <img src={arrow} alt="Логотип" />
                    </div>
                </div>
            </header>
        );
    }
}
export default Header;
