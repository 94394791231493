import React, { Component } from 'react';
import Header from './components/Header';
import Detail from './components/Detail';
import MainSlider from './components/MainSlider';
import close from "./close2.svg";

class App extends Component {
    render() {
        return (
            <div className="App">
                <div className="app-content">
                    <Header/>
                    <MainSlider/>
                </div>
                <Detail/>
                <div id="popupWin">
                    <img id="popupWin--img" src="/" alt=""/>
                    <div className="prev">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" fill-rule="evenodd" clip-rule="evenodd"><path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/></svg>
                    </div>
                    <div className="next">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" fill-rule="evenodd" clip-rule="evenodd"><path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/></svg>
                    </div>
                    <img className="popupWin--close" id="popupWin--close" src={close} alt="Логотип" />
                </div>
                <div id="popupWin2">
                    <div className="sertificate">

                    </div>
                </div>
                <div id="popupWin3">
                    <iframe id = "video-frame" width="1903" height="768" src="https://www.youtube.com/embed/3eHd1VLTQbg?enablejsapi=1" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                    <img className="popupWin--close" id="popupWin--close2" src={close} alt="Логотип" />
                </div>
          </div>
        );
  }
}
export default App;
