// export const DOMAIN_NAME = 'http://metalinvest.loc';
export const DOMAIN_NAME = '';

var language = window.navigator ? (window.navigator.language ||
    window.navigator.systemLanguage ||
    window.navigator.userLanguage) : "ru";
var currentLang = language.substr(0, 2).toLowerCase();
var cookieVar = document.cookie.split(/(?:,| )+/);
// var cookieVar2 = cookieVar[0].split(/(?:=| )+/)[1];
var cookieVar2;
for (var i = 0; i < cookieVar.length; i++) {
    if ('language=ru' == cookieVar[i] || 'language=ru;' == cookieVar[i]) {
        cookieVar2 = cookieVar[i].split(/(?:=| )+/)[1].replace(/[^a-zA-Z ]/g, "");
    }
    if ('language=en' == cookieVar[i] || 'language=en;' == cookieVar[i]) {
        cookieVar2 = cookieVar[i].split(/(?:=| )+/)[1].replace(/[^a-zA-Z ]/g, "");
    }
}
if (!cookieVar2){
    cookieVar2 = currentLang;
}
function count() {
    if (cookieVar2 == 'ru') {
        document.querySelector('.slick-slide[data-index="-1"] .slides-next-page--next-number').textContent = "01";
        document.querySelector('.slick-slide[data-index="-1"] .slides-next-page--next-title').textContent = "Торговля металлами";
        document.querySelector('.slick-slide[data-index="3"] .slides-next-page--next-number').textContent = "01";
        document.querySelector('.slick-slide[data-index="3"] .slides-next-page--next-title').textContent = "Торговля металлами";
    } else {
        document.querySelector('.slick-slide[data-index="-1"] .slides-next-page--next-number').textContent = "01";
        document.querySelector('.slick-slide[data-index="-1"] .slides-next-page--next-title').textContent="Metallinvest company";
        document.querySelector('.slick-slide[data-index="3"] .slides-next-page--next-number').textContent="01";
        document.querySelector('.slick-slide[data-index="3"] .slides-next-page--next-title').textContent="Metallinvest company";
    }
}
setInterval(function(){
    setTimeout(count, 2000);
    document.querySelectorAll('.header--locale .en').forEach(function(element) {
        element.onclick = function() {
            document.cookie = "language=en";
            window.location.reload();
        };
    });

    document.querySelectorAll('.header--locale .ru').forEach(function(element) {
        element.onclick = function() {
            document.cookie = "language=ru";
            window.location.reload();
        };
    });
}, 1000);
export const CUR_LANG = cookieVar2;
